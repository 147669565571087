@import '../_variable.scss';

.smk-changediv {
    padding: 20px;
}

.smk-switchmaindiv {
    padding: 25px;
}

.smk-switchcontainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    font-size: 14px;
    font-family: $font-default;
    color: $primary-dark-color !important;
}