$primary-color: #5A82F9;
$primary-dark-color: #1D1E4D;
$font-color: #0C0B13;
$secondary-color: #686868;
$form-input-color : #F7F8FC;
$link-color: #98ABE7;
$header-color: #111111;
$nav-background: #2ecc71;
$offline-color: #ffa500;
$disable-color: #a6abb9;
$success-color: #62c45bdc;
$online-color: #00ff00;
$light-color: #e7e7e7;
$nav-item: #1d1e4d;
$background-color: #eaf0fc;
$white-color: #fff;
$lighter-color: #b3b3b3;
$danger-color: #e35649;
$hourly-color: #c0cef5;
$daily-color: #f7caa2;
$daily-font-color: #52522b;
$weekly-color: #b7eb8f;
$monthly-color: #b088ad;
$schedule-color: #fffb8f;
$cancel-color: #ffa39e;
$xxxsm:380px;
$xxsm:430px;
$xsm: 480px;
$sm: 576px;
$md: 820px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;
$mheight: 415px;
$font-default: 'Poppins', sans-serif;
