@import '../_variable.scss';

.smk-header {
  background-color: $white-color;
  display: flex;
  justify-content: space-between !important;
  color: $header-color;
  align-items: center;
  height: 10vh;
  width: auto !important;
  top: 0;
  position: fixed;
  right: 0;
  left: 0;

  @media (max-width:$sm) {
    background-color: $white-color !important;
    height: 10vh !important;
  }
}

.smk-headerimagediv {
  width: 10vh;
  height: 8vh;
  padding-left: 8px;
  align-items: center;
  display: flex;
}

.smk-headerdropdown {
  border: none !important;
  outline: none !important;
}

.smk-header-logo {
  cursor: pointer;
  object-fit: contain;
}

.smk-navbar {
  position: fixed;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  bottom: 0;
  padding-top: 10px;
  right: 0;
  left: 0;
  height: 8vh;
  z-index: 999;
  background-color: $white-color;
  color: $primary-dark-color;

  box-shadow: 
        inset 0px 11px 8px -10px #7a7575,
        inset 0px -11px 8px -10px #7a7575;
}

.smk-navbar .am-tab-bar {
  height: 100%;
  display: flex;
  align-items: stretch;
}

.smk-navbar .am-tab-bar-tab {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: $primary-dark-color !important;
}

.smk-navbar .adm-tab-bar-item {
  color: $primary-dark-color !important;
  font-weight: 700;
}

.smk-navbar .adm-tab-bar-item-active {
  color: $primary-color !important;
  border-radius: 10px;
  font-size: 16px !important;
  font-weight: 900;
}

.smk-headericon {
  font-size: 40px;
  color: $white-color;
}

.smk-headerpopover {
  .adm-popover-menu-item-text {
    flex: auto;
    padding: 14px 20px 14px 0;
    border-top: none !important;
    font-size: 15px;
    font-weight: 800;
    font-family: $font-default;
  }
}

.smk-headerpopover-icon {
  color: $primary-color;
  font-size: 20px;
  font-weight: 800;
  margin-right: 10px;
  cursor: pointer;
}

.smk-profileItem {
  display: flex;
  cursor: pointer;
}

.smk-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
}