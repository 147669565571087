@import '../_variable.scss';

.smk-backicon {
    font-size: 30px;
    font-weight: 700;
    padding-top: 30px;
    cursor: pointer;

    &-divbutton {
        font-size: 16px !important;
        font-weight: 700;
        padding-top: 0px !important;
    }
}

.smk-settingscontainer {
    display: flex;
    margin: 20px;
}

.smk-settingsbutton {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    width: 90%;
    background-color: $primary-color;
    color: $light-color;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 20px;
    border-radius: 15px;
    cursor: pointer;
}

.smk-buttonsubmit {
    margin-top: 70px !important;
    padding-left: 200px !important;
    padding-right: 200px !important;
}

.smk-chckin-icon {
    color: $secondary-color;
    font-size: 25px;
    margin-top: 10px;
    margin-right: 10px;
}

.smk-p {
    font-size: 15px;
    font-family: $font-default;
    font-size: 25px;
    font-weight: 500;
}

.smk-chckin-item {
    width: 99%;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
}

.smk-scheduleitemcheck {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
}

.smk-chckin-title {
    font-size: 20px;
    font-family: $font-default;
    font-weight: 600;
}

.smk-chckin-p {
    font-size: 15px;
    font-family: $font-default;
    font-weight: 600;
}

.smk-checkinbutton {
    border: 1px solid red;
    background-color: $primary-color;
}

.smk-radiobutton {
    font-size: 24px;
    font-family: $font-default;
    padding-right: 20px;
}

.smk-modal {
    text-align: center;
}

.smk-modalicon {
    font-size: 30px;
    font-weight: 400;
}

.smk-modalbutton {
    background: $primary-color !important;
    border: none !important;
    color: $white-color !important;
    font-family: $font-default;
    margin: 10px;
    padding: 5px 15px;
    font-size: 17px;
    color: $white-color;
    border-radius: 10px;

    &:hover {
        background: $white-color !important;
        border: 1px solid $primary-color !important;
        color: $primary-color !important;
    }
}

.smk-modalbuttoncancel {
    background: $white-color !important;
    border: 1px solid $primary-color !important;
    color: $primary-color !important;
    font-family: $font-default;
    margin: 10px;
    padding: 5px 15px;
    font-size: 17px;
    color: $white-color;
    border-radius: 10px;

    &:hover {
        background: $primary-color !important;
        border: 1px solid $primary-color !important;
        color: $white-color !important;
    }
}

.smk-pform {
    font-size: 16px;
    font-family: $font-default;
    font-weight: 500;
    color: $secondary-color;
}

.smk-buttonsave {
    font-family: $font-default;
    background-color: $primary-color !important;
    color: $light-color !important;
    border: none !important;
    outline: none !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    padding-left: 27px !important;
    padding-right: 27px !important;
    border-radius: 10px !important;
    font-weight: 500 !important;
    font-size: 22px !important;
}

.smk-textarea {
    .adm-text-area-element {
        border: none !important;
        width: 100%;
        background-color: $white-color;
        border-radius: 7px;
        padding: 4px;
    }
}

.smk-maindiv {
    height: 100vh;
    // background-color: red;
}

.smk-buttondiv {
    padding: 7px;
    display: flex;
    justify-content: space-between;
}

.smk-jobscontainer {
    border: 1px solid $secondary-color;
    background-color: $white-color;
    border-radius: 5px;
}

.smk-jobtypes {
    color: $primary-dark-color;
    font-family: $font-default;
    font-size: 14px;
    padding-top: 10px;
}

.adm-jumbo-tabs-tab-title {
    font-size: 15px;
    font-weight: 600;
    border: 1px solid $primary-dark-color;
    padding: 5px 5px !important;
    border-radius: 10px;
}

.adm-jumbo-tabs-tab-active {
    color: $primary-color !important;
    font-weight: 600 !important;

    .adm-jumbo-tabs-tab-title {
        border: 1px solid $primary-color !important;
    }
}

.smk-staffmain {
    margin-bottom: 10px;
    background-color: $white-color !important;
    height: 100%;
    overflow-y: scroll;
    position: relative;
    bottom: calc(0px);

    @media screen and (max-height: 420px) {
        height: 74vh;
    }

    @media screen and (max-height:400px) {
        height: 69vh;
    }
}

.smk-detailsdiv {
    padding-top: 100px;
    height: 76vh;

    @media screen and (max-height: 600px) {
        height: 70vh !important;
    }

    @media screen and (max-height:500px) {
        height: 60vh !important;
    }
}

.smk-staffsidediv {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    font-family: $font-default;

    &-addbutton {
        padding-right: 15px !important;
    }
}

.adm-list-item-content-main {
    font-size: 12px !important;
}

.adm-list {
    --border-inner: none !important;
    --border-top: none !important;
    --border-bottom: none !important;
    --extra-max-width: 100% !important;
}

.adm-check-list-item-extra {
    font-size: 15px !important;
    font-weight: 600;
    line-height: 1;
    color: $primary-color !important;
}

.smk-imagediv {
    display: flex;
    padding-top: 5px;
    align-items: center;
    justify-content: center;
}

.adm-jumbo-tabs-header {
    border-bottom: none !important;
}

.smk-profile-image-container {
    width: 7vh;
    height: 7vh;
    border-radius: 50px;
    padding-right: 8px;

    &-profile {
        margin-top: 10px;
        width: 15vh;
        height: 15vh;
    }

    &_header {
        display: flex;
    }
}

.smk-profilecontainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.smk-profilename {
    color: $primary-dark-color;
    font-size: 25px;
    font-weight: 700;

    &_pin {
        font-size: 20px;
        padding-left: 5px;

        &_delete {
            font-size: 15px !important;
            color: $danger-color;
            padding-left: 0px !important;
            font-weight: 500;
            cursor: pointer;
        }
    }
}

.smk-profiletitlecontainer {
    padding: 4px;
}

.smk-titlecontainer {
    padding-top: 15px;
    padding-left: 20px;

    &_delete {
        margin-left: 25px;
        margin-right: 20px;
        margin-top: 250px;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-left: 0px !important;
        display: flex;
        justify-content: center;
        text-align: center;
        border-radius: 10px;
        color: #cf1322;
        border: 1px solid #cf1322;
    }
}

.smk-gobackcontainer {
    padding-top: 10px;
    padding-left: 15px;
    display: flex;
    cursor: pointer;
}

.smk-goacktitle {
    font-size: 15px;
    padding-top: 6px;
    padding-left: 10px;
    color: $primary-color;
    font-weight: 600;
}

.smk-editbutton-container {
    width: 90%;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;

    &-incident {
        width: 94%;
    }

    &_profile {
        padding-top: 18px;
        padding-left: 0px !important;
        justify-content: flex-end;
        // padding-bottom: 15px;
        width: 95%;
    }
}

.smk-editbutton .adm-button {
    background-color: $primary-color !important;
}

.smk-accept-buttoncontainer {
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_accept {
        justify-content: end !important;
    }
}

.smk-tasklist-checkbox .adm-checkbox .adm-checkbox-icon {
    border-radius: 50px !important;
}

.smk-tasklist-checkbox .adm-checkbox-content {
    font-size: 14px !important;
}

.adm-checkbox .adm-checkbox-icon {
    color: $white-color !important;
}

.smk-taskdiv {
    display: flex;
}

.smk-broomcontainer {
    width: 25px;
    padding-top: 10px;
    padding-right: 5px;
    margin-left: 10px;
    margin-right: 10px;
    width: 25px;
}

.smk-collapsetitle {
    font-size: 14px;
}

.smk-unableform {
    padding: 20px;
    padding-left: 25px;
}

.smk-unablediv {
    width: 100% !important;
    padding: 15px;
    padding-left: 0px !important;
    border-radius: 10px;
    background-color: $background-color;

    .adm-list-body {
        background-color: $background-color;
    }
    .adm-list-item {
        background-color: $background-color;
    }

    &_profile {
        width: 90% !important;
    }
}

.ant-select-selector .ant-select-selection-item {
    font-family: $font-default !important;
    font-size: 14px !important;
    width: 40% !important;
}

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    border: 1px solid $primary-dark-color !important;
}

.smk-tasklist-checkbox {
    &-inprogress {
        width: 100% !important;
        border-radius: 10px;
        cursor: pointer;

        &:hover {
            background-color: $white-color;
        }
    }
}

.smk-frequencycontainer {
    display: flex;
    padding-bottom: 15px;
}

.smk-task-checkbox {
    width: 15%;
    display: flex;
    align-items:first baseline;
    justify-content: center;
    padding-top: 10px;
}

.smk-task-p {
    width: 75%;
}

.smk-task-icon {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: $primary-dark-color;
    font-size: 14px;
    cursor: pointer;
}

.smk-frequencydivision {
    display: flex;
    justify-content: flex-start;
}

.smk-frequency {
    padding: 2px 20px;
    margin-left: 0px;
    border-radius: 6px !important;
    font-family: $font-default;
    width: 60px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    &_weekly {
        background-color: $weekly-color !important;
        color: #389e0d !important;
        border: 1px solid #389e0d;
    }

    &_daily {
        background-color: $daily-color !important;
        color: #e67e22 !important;
        border: 1px solid #e67e22;
    }

    &_monthly {
        background-color: $hourly-color !important;
        color: blue;
        border: 1px solid blue;
    }

}

.smk-collapse_task .adm-list-item {
    background-color: $white-color !important;
    width: 100%;
}

.smk-collapse_task {
    border-radius: 10px;
    
    &_notification {
        .adm-list-item {
            width: 310px !important;
        }
    }
    .adm-list-item {
        background-color: $form-input-color;
        width: 280px !important;
        font-size: 15px;
        font-weight: 700;
        border-radius: 10px;

        &:hover {
            background-color: $white-color;
        }
    }

    .adm-list-item-content-arrow {
        color: $primary-color !important;
        font-weight: 700;
    }
}

.smk-incident {
    padding-left: 15px;
    font-family: $font-default;
    font-size: 14px;
}

.smk-incidentform {
    width: 100% !important;
    background-color: $background-color;
    border-radius: 10px;

    &_progress {
        margin-left: 10px;
        margin-right: 10px;
        width: 90% !important;
    }

    &_profile {
        width: 93% !important;
        margin: 5px;
        margin-left: 10px;
    }
}

.smk-collapsediv {
    display: flex;
}

.smk-detailstag {
    color: $disable-color;

    &_description {
        cursor: pointer;
    }
}

.smk-buttonedit {
    width: 2vh;
    height: 2vh;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    color: $primary-dark-color;
    background-color: $primary-color;
    cursor: pointer;
    position: absolute;
    bottom: calc(1px);
    right: calc(24px);
}

.smk-modalcontent {
    display: flex;
    justify-content: flex-start;
}

.smk-unablediv {
    width: 90%;
}

.smk-updatepincontainer {
    width: 99%;
    padding-top: 25px;
    display: flex;
    justify-content: flex-end;
}

.smk-updatepin {
    margin-left: 8px;
}

.smk-descriptiondiv {
    display: flex;
}

.smk-desleft {
    width: 60%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.smk-desright {
    width: 40%;
}

.smk-tasktitle {
    font-size: 15px;
    font-weight: 700;
    color: $primary-dark-color;
    display: flex;
    justify-content: flex-start;
}

.smk-scheduledisplaydiv {
    display: flex;
}

.smk-scheduledisplaydivleft {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.smk-scheduledisplaydivright {
    width: 100%;
}