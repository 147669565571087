@import '../_variable.scss';

.smk-home-div {
    display: flex;
    justify-content: space-between;
}

.smk-home-div-left {
    align-self: flex-start;
}

.smk-home-div-right {
    align-self: flex-end;
}

.smk-homedivmain {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.smk-outline-icon {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    color: $secondary-color;
}

.smk-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px;
    background-color: #f5f5f5;
}

.smk-createjob {
    position: fixed;
    bottom: calc(80px + 20px);
    right: 0;
    height: 200px;
    width: 300px;
    border-top: 1px solid $secondary-color;
    border-bottom: 1px solid $secondary-color;
}
.smk-createstaffjob {
    position: fixed;
    bottom: calc(20px);
    right: 0;
    height: 200px;
    width: 300px;
}

.smk-iconcreatejob {
    font-size: 16px;
    color: $white-color;
}