@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Noto+Serif:wght@400;700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import '_variable.scss';
@import './partial/splash.scss';
@import './partial/signup.scss';
@import './partial/home.scss';
@import './partial/createjob.scss';
@import './partial/staff.scss';
@import './partial/home.scss';
@import './partial/header.scss';
@import './partial/notifications.scss';
@import './partial/settings.scss';
@import './partial/alerts.scss';
@import './partial/date.scss';

.upload-image-btn {
    background-color: #5A82F9;
    color: white;
    padding: 8px 15px;
    border-radius: 12px;
    font-weight: 500 !important;
    margin-top: 20px !important;
    margin-left: 20vw !important;
}