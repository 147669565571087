@import '../_variable.scss';

.smk-collapse_panel {
    font-size: 15px !important;
    font-family: $font-default;
    background-color: $form-input-color !important;
    font-weight: 600;
    margin-top: 10px;

    .adm-list-item-content-main {
        font-size: 15px !important;
    }

    &:hover {
        background-color: $white-color !important;
    }
}

.smk-collapse_description {
    font-size: 13px !important;
    font-family: $font-default;
}

.smk-notificationscheckbox {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
}

.smk-collapsecontainer {
    padding-top: 5px;

    &_notification {
        display: flex;
        justify-content: center;
        padding: 10px;
        padding-top: 30px;
        font-size: 15px;
        font-weight: 600;
        color: $primary-dark-color;
        font-family: $font-default;
    }
}

.smk-markascontainer {
    margin-right: 15px;
    padding-top: 15px;
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
    font-weight: 600;
    font-family: $font-default;
    color: $primary-color;
}

.smk-marktitle {
    cursor: pointer;
}

.smk-titlediv {
    display: flex;
}

.smk-viewibutton {
    cursor: pointer;
}

.smk-status {
    padding: 4px 20px;
    margin-left: 0px;
    border-radius: 6px !important;
    font-family: $font-default;
    width: auto !important;
    font-weight: 400;
    text-align: center;

    &_completed {
        background-color: $weekly-color !important;
        color: #389e0d !important;
        border: 1px solid #389e0d;
    }

    &_scheduled {
        background-color: $schedule-color !important;
        color: #d4b106 !important;
        border: 1px solid #d4b106;
    }

    &_cancelled {
        background-color: $cancel-color !important;
        color: #cf1322;
        border: 1px solid #cf1322;
    }

    &_progress {
        background-color: #3498db !important;
        border: 1px solid rgb(22, 22, 94);
        color: rgb(22, 22, 94);
    }
}

.smk-collapse_notify {
    border-radius: 10px;
    .adm-list-item {
        background-color: $white-color;
        width: 300px !important;
        font-size: 15px;
        font-weight: 700;
        border-radius: 10px;
    }

    .adm-list-item-content-arrow {
        color: #1677FF !important;
        font-weight: 700;
    }
}

.smk-notifycontainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
}

.smk-notificationdetailscontainer {
    display: flex;
}

.smk-notificationdetailsleft {
    width: 30%;
}

.smk-notificationdetailsright {
    width: 100%;
    padding-left: 10px;
}

.smk-notificationdate {
    align-items: end;
    display: flex;
    justify-content: flex-end;
}

.smk-mainnotifycontainer {
    display: flex;
    cursor: pointer;
}

.smk-mainnotifycontainerleft {
    width: 100%;
}

.smk-mainnotifycontainerright {
    width: 100%;
    display: flex;
    align-items:end;
    justify-content: end;
}