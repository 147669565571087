@import '../_variable.scss';

.smk-warningimagecontainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;
}

.smk-modaldetail {
    padding-left: 5px;
}

.smk-modal {
    .adm-modal-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 55%;
        margin-left: 100px;
        float: right !important;
    }

    &_alert {
        .adm-modal-footer {
            display: flex;
            flex-direction: row;
            width: 100%;
            margin-left: 0px;
            justify-content: center !important;
        }
    }

    .adm-space {
        --gap-vertical: 0px;
    }

    .adm-modal-button {
        border: 1px solid $success-color;
        border-radius: 12px;
        background-color: $success-color;
        color: $white-color;
        padding: 5px 20px !important;

        &:hover {
            background-color: $white-color;
            border: 1px solid $success-color;
            color: $success-color;
        }
    }

    .adm-button:not(.adm-button-default).adm-button-fill-none {
        border: 1px solid $danger-color;
        background-color: $white-color;
        color: $danger-color;
        padding: 5px 20px;

        &:hover {
            background-color: $danger-color;
            color: $white-color;
        }
    }
}