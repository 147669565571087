@import '../_variable.scss';

.smk-mainContainer {
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
}

.smk-subContainer {
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
}

.adm-checkbox .adm-checkbox-icon {
    border: 2px solid $primary-color;
    font-family: $font-default;
}

.smk-title {
    font-size: 50px;
    text-align: center;
    font-family: $font-default;
    font-weight: 700;
    color: $primary-dark-color;

    &-confirmpage {
        font-size: 32px !important;
    }

    @media screen and (max-width: 500px) {
        font-size: 30px !important;
    }
}

.smk-titlelogin {
    font-size: 50px;
    font-family: $font-default;
    font-weight: 700;
    padding-left: 10px;
    color: $primary-dark-color;

    @media screen and (max-width: 500px) {
        font-size: 25px !important;
    }
}

.smk-pagep {
    color: $primary-dark-color;
    font-family: $font-default;
    font-weight: 500;
}

.smk-titleheadergrid {
    text-align: center;
    padding-top: 30px;

    &-login {
        text-align: center;
        padding-top: 50px;
        padding-bottom: 30px;
    }
}

.smk-titleheader {
    font-size: 25px;
    font-family: $font-default;
    font-weight: 500;
}

.smk-buttongrid {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
}

.smk-button {
    background-color: $primary-color !important;
    border: none !important;
    outline: none !important;
    font-family: $font-default !important;
    padding: 12px 100px !important;
    font-size: 18px !important;
    color: $white-color !important;
    font-weight: 700 !important;
    border-radius: 30px !important;

    &:hover {
        background: $white-color !important;
        border: 1px solid $primary-color !important;
        color: $primary-color !important;
        border-radius: 30px !important;
    }

    &_date {
        padding: 5px !important;
    }
    &-signup {
        padding: 12px 100px !important;
    }

    &-edit {
        padding: 10px 20px !important;
        border-radius: 12px !important;

        &:hover {
            background: $white-color !important;
            border: 1px solid $primary-color !important;
            color: $primary-color !important;
            border-radius: 12px !important;
        }
    }

    &_view {
        padding: 4px 10px !important;
        border-radius: 8px !important;
        font-weight: 500 !important;
        font-size: 15px !important;
        background-color: $white-color !important;
        color: $primary-color !important;
        border: 1px solid $primary-color !important;

        &:hover {
            background: $primary-color !important;
            color: $white-color !important;
            border-radius: 12px !important;
        }
    }

    &-editprofile {
        padding: 2px 20px !important;
        border-radius: 6px !important;

        &:hover {
            background: $white-color !important;
            border: 1px solid $primary-color !important;
            color: $primary-color !important;
            border-radius: 6px !important;
        }
    }

    &-reject {
        padding: 10px 20px !important;
        border-radius: 12px !important;
        background-color: $white-color !important;
        color: $primary-color !important;
        border: 1px solid $primary-color !important;

        &:hover {
            background: $primary-color !important;
            color: $white-color !important;
            border-radius: 12px !important;
        }
    }

    &-link {
        background-color: $white-color !important;
        color: $primary-color !important;
        border: none !important;
        outline: none !important;
        padding: 15px !important;
        border-radius: 10px !important;
        font-family: $font-default;
        font-weight: 800;
        font-size: 15px;

        &:hover {
            font-weight: 700;
            background-color: $white-color !important;
        }

        &-login {
            font-weight: 900 !important;
            padding: 0 !important;
        }
    }

    &-frogetpasswordlink {
        background-color: $white-color;
        color: $link-color;
        border: none !important;
        outline: none !important;
        padding: 15px !important;
        border-radius: 10px;
        font-family: $font-default;
        font-weight: 700;
        font-size: 15px;

        &:hover {
            font-weight: 700;
        }
    }

    &_createjob {
        right: 0;
        position: fixed;
        bottom: calc(80px + 40px);
        width: 80px;
        height: 60px;
    }

    &_staffjob {
        right: calc(20px);
        position: fixed;
        bottom: calc(40px);
        width: 80px;
        height: 60px;
        padding: 12px 20px !important;
        color: $white-color !important;

        &:hover {
            background: $white-color !important;
            border: 1px solid $primary-color !important;
            color: $primary-color !important;

            .smk-iconcreatejob {
                color: $primary-color !important;
            }
        }
    }

    &-staffjobcreate {
        padding: 8px 30px !important;
        
        &:hover {
            .smk-iconcreatejob {
                color: $primary-color !important;
            }
        }
    }

    &-submit {
        padding-left: 40px !important;
    }

    &-confirm {
        background-color: $primary-color;
        border: none !important;
        outline: none !important;
        font-family: $font-default;
        font-size: 16px;
        font-weight: 700;
        color: $white-color;
        border-radius: 10px;
        padding: 12px 20px !important;
    }

    @media screen and (max-width: 500px) {
        font-size: 16px !important;
    }
}

.smk-viewmorebutton {
    display: flex;
    justify-content: end;
    align-items: end;
}

.smk-splashbutton {
    background-color: $primary-color !important;
    border: none !important;
    outline: none !important;
    font-family: $font-default !important;
    padding: 12px 100px !important;
    font-size: 18px !important;
    color: $white-color !important;
    font-weight: 800;
    border-radius: 30px !important;

    &:hover {
        background: $white-color !important;
        border: 1px solid $primary-color !important;
        color: $primary-color !important;
    }
}

.smk-buttonlink {
    background-color: $white-color;
    cursor: pointer;
    color: $primary-color;
    border: none !important;
    outline: none !important;
    padding: 15px !important;
    border-radius: 10px;
    font-family: $font-default;
    font-weight: 700;
    font-size: 15px;

    &:hover {
        font-weight: 900;
    }
}

.smk-backutton {
    background: $white-color !important;
    border: 1px solid $primary-color !important;
    color: $primary-color !important;
    outline: none !important;
    font-family: $font-default;
    padding: 12px 60px !important;
    font-size: 16px;
    color: $white-color;
    font-weight: 700;
    border-radius: 10px;

    &:hover {
        background: $primary-color !important;
        border: 1px solid $primary-color !important;
        color: $white-color !important;
    }

    &-view {
        padding: 7px 20px !important;
    }
}

.smk-loginbuttoncontainer {
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.smk-loginbuttoncontainerp {
    font-size: 14px !important;
    font-family: $font-default;
    font-weight: 700 !important;
    color: $primary-dark-color;

    &-checkbox {
        padding-left: 25px;
    }

    &-checkboxcontainerp {
        padding-left: 8px !important;
        padding-right: 8px !important;
        font-size: 16px !important;
    }
}

.smk-linktext {
    font-family: $font-default;
    font-size: 15px;
}

.smk-login-button-maincontainer {
    text-align: center;
}

.smk-footerspan {
    color: $primary-color;
}

.smk-logocontainer {
    padding-top: 20px;
    height: 10vh;
}

.smk-splashimagecontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55vh;

    &_splash {
        height: 40vh;
    }

    &_logo {
        height: 40vh;
    }

    &_signup {
        height: 45vh;
    }
}

.smk-griditem {
    padding-top: 30px;
}

.smk-splashfootercontainer {
    height: 8vh;
    bottom: 0;
    position: fixed;
    right: 0;
    left: 0;
    text-align: center;
}

.smk-startdiv {
    height: 100vh;
} 

.smk-logodiv {
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.smk-authorization {
    color: $primary-dark-color;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    font-family: $font-default;
}

.smk-buttoncontainerstart {
    display: flex;
    padding-top: 10px;
    margin-bottom: 100px;
    align-items: center;
    justify-content: center;
}