@import '../_variable.scss';

.smk-subtitle {
    font-size: 16px;
    font-family: $font-default;
    font-size: 25px;
    font-weight: 700;
}

.smk-formInput {
    display: flex;
}

.smk-icon {
    margin-right: 15px;
    margin-top: 15px;
    color: $secondary-color;
}

.smk-signup-image {
    @media (max-width:$xsm) {
        height: 75% !important;
    }
    @media (max-width:$xxsm) {
        height: 70% !important;
    }
    @media (max-width:$xxxsm) {
        height: 60% !important;
    }
}

.smk-form {
    font-family: $font-default !important;
    color: $primary-dark-color !important;
}

.smk-form-input {
    width: 95%;
    color: $primary-dark-color !important;

    &-login {
        width: 90% !important;
    }

    &-image {
        width: 60% !important;
    }
}

.smk-loginicon {
    margin-right: 15px;
    margin-top: 5px;
    color: $secondary-color;
    font-size: 20px;
}

.smk-form-input .adm-input-element {
    font-family: $font-default;
    width: 100% !important;
    font-size: 16px;
    font-weight: 700;
    border: 1px solid $secondary-color;
    border-radius: 7px;
    padding: 4px;

    &:hover {
        border: 1px solid $primary-color;
    }
}

.smk-formheader {
    text-align: center;

    &-verify {
        display: flex;
    }
}

.smk-formheaderp {
    font-size: 16px;
    font-weight: 200;
    font-family: $font-default;
    font-weight: 700;
}

.smk-formheadercaption {
    font-size: 14px;
    font-weight: 200;
    font-family: $font-default;
    font-weight: 700;
}

.smk-buttoncontainer {
    display: flex;
    justify-content: space-between;
}

.smk-backbuttondiv {
    bottom: calc(40px);
    left: 0;
    position: fixed;
}

.smk-submitbuttondiv {
    padding-top: 20px;

    &-forgot {
        padding-top: 40px !important;
    }
}

.smk-signinbuttoncontainer {
    padding-top: 8px;
}

.adm-form {
    ---border-inner: none !important;
    ---border-top: none !important;
    color: $header-color !important;
}

.adm-form-item.adm-form-item-vertical .adm-form-item-label {
    font-weight: 700;
    color: $primary-dark-color;
    font-family: $font-default;
    font-size: 14px !important;
}

.smk-imageupload {
    border: 1px dashed $secondary-color;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    font-family: $font-default;
    background-color: $white-color;
    color: $primary-dark-color;
    padding-bottom: 10px;
    font-size: 14px;

    .smk-uploadicon {
        color: $primary-dark-color;
        font-size: 14px;
    }

    &:hover {
        background: #ffffff !important;
        border: 1px dashed $primary-color !important;
        color: $primary-color !important;

        .smk-uploadicon {
            color: $primary-color;
        }
    }
}

.smk-uploadicon {
    margin-right: 15px;
    margin-top: 15px;
    color: $white-color;
    font-size: 16px;
    font-weight: 700;
}

.smk-signupheader {
    font-family: $font-default;
    font-size: 15px;
    color: $primary-dark-color;
    font-weight: 700;
}

.smk-uploadicon {
    color: $white-color;
    font-size: 18px;
    font-weight: 700;
}

.adm-passcode-input {
    --cell-size: 30px !important;
    height: 5vh;
}

.smk-form-input .adm-input-element {
    border: none !important;
    background-color: $form-input-color !important;
    padding: 8px;
    font-weight: 300;
    color: $primary-dark-color !important;
}

.smk-form-input .adm-input-element:hover {
    border: 1px solid $primary-color;
}

.adm-form-item.adm-form-item-vertical .adm-form-item-label {
    color: $primary-dark-color !important;
}

.smk-inputpin {
    .adm-passcode-input-cell {
        width: 55px !important;
        height: 55px !important;
        border: none !important;
        margin: 5px;
        padding: 5px;
        border-radius: 6px;
        background-color: $form-input-color !important;
        height: 10vh;

        &:hover {
            --border-color: $link-color !important;
            outline: none !important;

            .adm-passcode-input-cell {
                border: $link-color !important;
            }
        }
    }
    
    &_profile {
        .adm-passcode-input-cell {
            background-color: $white-color !important;
            margin-bottom: 10px !important;
    
            &:hover {
                --border-color: $link-color !important;
                outline: none !important;
    
                .adm-passcode-input-cell {
                    border: $link-color !important;
                }
            }
        }
    }


}

.adm-passcode-input {
    --border-color: none !important;
    height: 50px;
    outline: none;
}

.adm-passcode-input-cell {
    width: 62px !important;
    height: 62px !important;
    border: none !important;
    margin: 5px;
    border-radius: 6px;
}

.smk-footerdiv {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
}

.smk-loginformInput {
    display: flex;

    .smk-form-input .adm-input-element {
        border: none !important;
        outline: none !important;
    }
}

.smk-modaldiv {
    display: flex;
    justify-content: end;
}

.smk-forgotpasswordcontainer {
    display: flex;
    justify-content: end;
    width: 95%;
    color: $link-color !important;

    &-incident {
        width: 90% !important;
        padding-right: 10px !important;
        margin-right: 10px !important;
    }
}

.smk-logoimage {
    height: '80%';
    width: auto;
    object-fit: cover;
    padding: 10px;
}

.smk-confirmformgrid {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 40px;
}

.smk-signupgrid {
    overflow: scroll;
    padding-top: 45px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
}

.smk-logingrid {
    overflow: scroll;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
}