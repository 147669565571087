@import '../_variable.scss';

.smk-switchdiv {
    position: fixed;
    padding-top: 10px;
    right: calc(35px);
}

.smk-allday {
    padding-right: 10px;
}

.smk-checkoxcontainer {
    padding-top: 10px;
    font-family: $font-default;
}

.smk-changedate {
    justify-content: space-between;
}

.smk-bottomdiv {
    padding-left: 24px;
}

.smk-link {
    text-decoration: none;
    font-family: $font-default;
}

.smk-addbutton {
    border: none !important;
    outline: none !important;
    color: blue !important;
    font-family: $font-default;
}

.smk-stafficon {
    margin-top: 10px !important;
}

.smk-schedul-row {
    display: flex;
    padding-top: 3px;

    &_notifications {
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 8px;
        margin-right: 15px;
        margin-top: 15px;
        margin-bottom: 15px;
        color: $primary-dark-color !important;
        font-size: 14px;
        font-family: $font-default;
    }
}

.smk-schedul-details {
    width: 90%;
    font-family: $font-default;
    color: $primary-dark-color;
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.smk-scheduleitemname {
    font-size: 15px;
    font-weight: 700;

    &_detail {
        font-weight: 500;
    }
}

.smk-scheduleitemnamediv {
    padding-bottom: 3px;

    &_right {
        text-align: right;
    }
}

.smk-scheduleitemnamedivright {
    display: flex;
    justify-content: flex-start;
    align-items: start;
}

.smk-scheduleitemnamedivleft {
    display: flex;
    justify-content: flex-end;
    align-items: end;
}

.smk-schedul-status {
    width: 20%;
    margin: auto 0;
    display: flex;
    justify-content: center;
}

.smk-schedul-statusonline {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    background-color: $online-color;
}

.smk-schedul-statusoffline {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    background-color: $offline-color;
}

.smk-schedul-item {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 8px;
    margin: 18px;
    margin-left: 3px;
    margin-right: 3px;
    color: $primary-dark-color !important;
    font-size: 14px;
    font-family: $font-default;
}

.adm-jumbo-tabs-tab {
    font-family: $font-default !important;
    font-size: 16px !important;
    font-weight: 400;
}

.smk-viewbutton .adm-button {
    background-color: whitesmoke !important;
    border: none !important;
    outline: none !important;
    text-decoration: none !important;
    font-family: $font-default !important;
}

.smk-monthdiv {
    position: relative;
    top: calc(0px);
}