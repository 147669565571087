.date {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    padding: 8px;
    cursor: pointer;
  }
  
  .current-date {
    color: $primary-color;
    border: 1px solid $primary-color;
    width: 5vh;
    height: 5vh;
  }
  
  .selected-date {
    color: $primary-color;
    border: 1px solid $primary-color;
    width: 5vh;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
  }
  
  .day {
    font-weight: 700;
    font-family: $font-default;
  }
  
  .date-number {
    font-size: 20px;
  }

  .smk-datecell {
    display: flex;
    padding: 10px 15px;
  }

  .week-navigation {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .smk-nextbutton {
    border: none !important;
    outline: none;
    color: $primary-dark-color;
    font-size: 15px;
  }